import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "./image-gallery.css";
import { isMobile } from "react-device-detect";
import MobileView from "./MobileView";
import View from "./View";
import InfoView from "./InfoView";

//import * as rdd from "react-device-detect";
//rdd.isMobile = true;

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={isMobile ? <MobileView /> : <View />}></Route>
        <Route path="/info" element={<InfoView />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
