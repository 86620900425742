import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const Help = ({ open, handleClose }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Bienvenides a fuepoesia.ar
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Toque las imágenes para acceder a los poemas, y presione la "X"
          ubicada en la esquina superior derecha para volver hacia atrás.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Continuar</Button>
      </DialogActions>
    </Dialog>
  );
};

export default Help;
