import React from "react";
import Typography from "@mui/material/Typography";
import ImageGallery from "react-image-gallery";
import { isMobile } from "react-device-detect";
import { CgCloseO } from "react-icons/cg";

const PoemContent = ({ poem, onClose }) => {
  const images = poem ? (
    poem?.images?.map((img, index) => {
      return {
        original: img?.url || img,
        thumbnail: img?.url || img,
        description: img?.description,
      };
    })
  ) : (
    <></>
  );

  return poem ? (
    <div
      style={{
        display: "flex",
        flexDirection: isMobile ? "column" : "row",
        wordBreak: "break-word",
        textAlign: "center",
        height: "100%",
        marginTop: isMobile ? "25px" : undefined,
        overflowY: isMobile ? "auto" : undefined,
      }}
    >
      <div
        id="close-button"
        style={{
          position: "absolute",
          right: isMobile ? 50 : 20,
          top: isMobile ? 75 : 20,
          zIndex: 5,
          cursor: "pointer",
          fontWeight: "bold",
          fontSize: "2rem",
        }}
        onClick={() => {
          onClose();
        }}
      >
        <CgCloseO />
      </div>
      <div
        style={{
          marginTop: 15,
          width: isMobile || poem.disableImages ? "100%" : "40%",
          borderRight: 1,
          borderLeftColor: "black",
          //overflowX: 'hidden',
          overflowY: isMobile ? undefined : "auto",
          textAlign: poem.disableImages ? "center" : "left",
        }}
      >
        <Typography
          id="transition-modal-title"
          variant="h6"
          component="h2"
          style={{ marginBottom: 15 }}
        >
          {poem.title}
        </Typography>
        {Array.isArray(poem.poem) ? (
          poem.poem.map((poemItem) => (
            <p
              style={{
                whiteSpace: poemItem.whiteSpace || "pre-line",
                lineHeight: 1.8,
                textAlign: poemItem.align,
                tabSize: 2,
                margin: 0,
                marginTop:
                  isMobile && !poemItem?.title?.length ? "35px" : undefined,
                marginRight: !isMobile ? 20 : undefined,
                fontSize: (isMobile && "2.5vw") || undefined,
              }}
            >
              {poemItem.text}
            </p>
          ))
        ) : (
          <p
            style={{
              whiteSpace: "pre-line",
              lineHeight: 1.8,
              textAlign: "left",
              tabSize: 2,
              marginRight: !isMobile ? 20 : undefined,
              fontSize: (isMobile && "2.5vw") || undefined,
            }}
          >
            {poem.poem}
          </p>
        )}

        <div style={{ textAlign: "right", marginRight: 20 }}>
          <p
            style={{
              whiteSpace: "pre-line",
              fontStyle: "italic",
              fontSize: (isMobile && "2.5vw") || undefined,
            }}
          >
            {poem.sign}
          </p>
        </div>
        <div style={{ textAlign: "left", marginLeft: 20 }}>
          <br />
          <p
            style={{
              whiteSpace: "pre-line",
              fontStyle: "italic",
              fontSize: (isMobile && "2.5vw") || undefined,
            }}
          >
            {poem.explanation}
          </p>
        </div>
      </div>
      {!poem.disableImages ? (
        <div
          style={{
            width: isMobile ? "100%" : "60%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div key={`carousel`} style={{ heigth: "100%" }}>
            <ImageGallery items={images} autoPlay slideInterval={6000} />
          </div>
        </div>
      ) : null}
    </div>
  ) : (
    <></>
  );
};

export default PoemContent;
