const colors = ["#CBC5EA", "#73628A", "#313D5A", "#183642"];
const getRandom = (array) => array[Math.floor(Math.random() * array.length)];

const poesias = [
  {
    title: "",
    poem: [
      {
        text: `Time present and time past
        Are both perhaps present in time future,
        And time future contained in time past. 
        If all time is eternally present
        All time is unredeemable.	
        
        
        (T. S. Eliot – Cuatro Cuartetos, Burnt Norton, I)
        
        
        Tiempo presente y tiempo pasado
        Ambos están quizás presentes en tiempo futuro,
        Y tiempo futuro contenido en tiempo pasado.
        Si todo tiempo es eternamente presente
        Todo tiempo es irredimible.    
      `,
        align: "center",
      },
      {
        text: `        
        -----------------------------------------------

        en una siempre hay
        por lo menos dos ciudades
        que son país y estaciones
         dos o más en una por lo menos
        historias hay siempre
      `,
        align: "center",
      },
    ],
    images: ["/img/poems/0/1.png"],
    disableImages: true,
    sign: undefined,
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": de las Luces",
    poem: `una primera luz
  alcanzó la mañana
  el fruto era ya de otro marzo
  con cáscara blanda y pulpa
  arenosa cual zamba, el sabor
  era el granito - un lápiz sin punta
  mordido en la culata, pintó
  no salir a la calle, por las dudas
  pintó - color piel sobraba en las cajas
  el corazón aún era dulce
  la semilla es aún lo que pudo
  ser manzano
  `,
    images: [
      {
        url: "/img/poems/1/1.jpg",
        description: `Anunciación (1928) 
        Alfredo Guttero (1882-1932)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/1/2.jpg",
        description: `Naturaleza muerta “faisán y frutas”
        Alfredo Guttero (1882-1932) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    sign: null,
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": y... festación, Man",
    poem: `
    Man, me tiran los huesos mal
    escuchá el galope la promesa
    que ni los puntos cardinales
    van a poder separar mis partes
    vas a tener que cortarme la bocha
    para verme - embanderar la plaza
    embadurnar los diarios
    carpas y tu vieja en la calle
    cuántas casas querés, te damos
    cuántos     pibes tenés, los llevamos
    al hombro las medias
    te quiero ver oscuro y flaco
    escuchá el bombo que pare
    la historia en tres clases
    se viene otra vuelta
    colectivos en la rotonda
    salideras de las escuelas
    qué flash, cómo no se rompen
    los dientes de tanto masticar
    apretar y seguir - escuchar un crack
    qué sabes de todo lo que hay
    que hacer para llegar a hacer
    y benteveo al verte flamearme
    así, ensimismado en tu rabia
    cagado en las patas de tanto 
    cortame y seguí cortando
    que esto es el nuevo realismo
    sea en Cuzco o en la Belgrano
    los cortes nos duelen a todos
    para que así también les duelan
    a ellos
  `,
    sign: `a las Manifestaciones que vienen
  desde antes de Berni
  y que van a seguir
  `,
    images: [
      {
        url: "/img/poems/2/1.jpg",
        description: `Manifestación (1934)
      Antonio Berni (1905-1981)
      MUSEO DE ARTE LATINOAMERICANO DE BUENOS AIRES`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": otra vez Sopa",
    poem: `la mesa está puesta al pie
    al fondín llegaste subiendo
    un centavo era, tanto peso
    salió con acelga / tronquito
    de tu canasta el ticket 
    y un hueso de la suerte
    pata con quebracho lepra
    en serie repartidos, zapallos
    sonrisas con dientes de ajo
    prados y hélices de élite
    llegaste a poner el mantel
    a piernas abiertas te quedó
    esa costumbre de cuando
    las empanadas chorreaban su jugo
    al asfalto a la vuelta, de la terminal
    mojar el pan es hacer un retrato
    las tripas descalzas de más
    que aquellas famosas
    tierras bajas
  `,
    sign: `al “costumbrismo” de Giudici
    sea el de Venecia o el de por acá no más
  `,
    images: [
      {
        url: "/img/poems/3/1.jpg",
        description: `La sopa de los pobres (1884) 
      Reinaldo Giudici (1853-1921) - MNBA
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": el Desesperar de la criada",
    poem: `si la casa vuelve en eco
    el sol te hace picar la mano
    baila baila bailarina
    cama adentro
    del parque de Chacarita
    tu piel que se cree blanca
    juega a hacer brillar ese sol
    con las joyas de la doña
    el neceser - cómo sería
    si fuera tuyo, el reflejo
    te decís mientras guardás
    piedra que soltó la cadenita
    fue el otoño de sus palabras
    preciosa te dijo: llevála
    escondida entre esos labios
    guardás porque la pérdida
    si nadie vio nada
    si testigos sobraron
    va a ser siempre solo tuya
  `,
    sign: `a la querencia de lo natural
    las criadas, los paisajes y la pampa
    al Eduardo Sívori de ida y vuelta
  `,
    images: [
      {
        url: "/img/poems/4/1.jpg",
        description: `El despertar de la criada (1887) 
      Eduardo Sivori (1847-1918) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": con Pan y con Trabajo",
    poem: [
      {
        text: `no me gusta el queso rallado con la salsa
        hace frío y el ambiente es grande
        en el comedor de casa está ese cuadro
        nunca me pareció así tan lindo nunca
        había visto al chico porque la mamá
        es un hombre en cuero y ahí está
        en brazos hasta hoy - 
        nos sentamos a la mesa
        y lo tengo 
        de frente un plato de polenta, al lado
        del hogar que nunca prendemos.
    
      `,
        align: "right",
      },
      {
        text: `me prendo como garrapata
    me llevan de la mano, al cruzar la calle
    me voy - dejando que me lleven, no más
    que las horas se me pasan como el frío, no más
    tengamos para ir a comprar una bandeja
    a la feria de la Necochea, el guiso
    caliente con pancito entero - partamos
    me llevan de nuevo me voy contento
    que trabajo tengo y ni siquiera me tengo
    ni tampoco cualquier otra cosa.
      `,
        align: "left",
      },
    ],
    sign: `al Ernesto de la Cárcova del 1890 o por ahí
    y a todos los calcos que andan por acá
  `,
    images: [
      {
        url: "/img/poems/5/1.jpg",
        description: `Sin pan y sin trabajo (1894) 
      Ernesto de la Cárcova (1866-1927)
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": y cadenas de humo",
    poem: `Durante la tormenta me clavé una astilla
    en el medio del ruido y con dos bolsas
    de sesenta kilos puestas encima
    la sentí entrar y quedarse
    yo era puro indio y carbón, ella
    madera de muelles traía 
    algo de ese balanceo turbio				
    el puntillismo de la lluvia 
    me enseñó que al bautismo
    uno lo puede practicar
    la sentí entrar y yo la olvidé,
    ella quedó ahí hasta que el cuerpo
    la fue sacando solo - después
    vino el pibe y supe 
    sin saber / qué hacer
    y él supo poner sauces
    donde solo veíamos pan
    y cadenas de humo
  `,
    sign: `al Benito que fue también Juan Martín
    y  pintor de puertos
  `,
    images: [
      {
        url: "/img/poems/6/1.jpg",
        description: `Elevadores a pleno sol (1945) 
      Benito Quinquela Martín (1890-1977) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": alegoría 51",
    poem: `que vengan todos al asado
    hay chorizo y pan - achuras
    de las tablas a la parrilla chistan
    los cuatro anillos del nibelungo
    
    duendes te sacan las llaves
    para que no te puedas ir con brasas
    en las manos brea llanto - un gatito
    chacarerea con las damas
    que se dan con mar y veneno 
    en el palco presidencial 
    
    ad honorem se pasan máscara y voz
    del sordo al prodigio se vuelve
    al preludio, las recitaciones de Orfeo
    y lanza que perforó el costado
    
    la sobremesa es la comedia
    fantasía compuesta por más 
    de uno que cuelga del techo papel
    que prendió el chamuyo de la blonda
    de travestis - tubérculos, condesas 
    infieles y amadas inmortales
    
    cuando la cantoría tire bollos al caballo
    blanco de madrugada nos tapamos
    con las dieciséis telas de Soldi
    para seguir y matear la mañana 
    
    en el gran fiordo de América no cabe
    otro nombre - con cáliz en mano 
    ni la popular durmiendo en el foyer
  `,
    sign: `al Teatro Colón y al verano del ’66 de Soldi
  `,
    images: [
      {
        url: "/img/poems/7/1.jpg",
        description: `Alegoría a la música, al canto y al baile (La cúpula del Teatro Colón, 1966) 
      Raúl Soldi (1905- 1994) 
      TEATRO COLON`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": la otra cautiva",
    poem: `Yo no soy mujer, señor mío
    hace tiempo lo fui y rezo 
    porque es que ya no recuerdo
    a quien cantaba y solía pedir
    no sorprende al ojo que mire así
    desde abajo algo enamorado
    vestido de gala - escarapela
    en la voz de mis madres cobres
    en el dolor quiero quedarme 
    aquí el día se esconde y el maestro
    de ojos esquivos me abre
    los dedos para cortar con papel
    qué sería de mí al volver a veces
    me pienso en los campamentos
    el malón trajo libros de escuela
    bibliotecas - todos los volúmenes
    gente de bien no había más que plantar
    rosales con la promesa del clavel
    ya en arena se quema desierto
    volviendosé transparente
    hoy me falta el aire al parir
    en una estancia: verlo bañar
    a mis niños entre paredes - a los días
    de nacidos 
    lloran mis pechos la sal
    me reclaman la piel con aullidos
    civilizada ahora soy, abono fértil
    qué sería de ellas al verme volver
    con perlas en boca de loba pampa
    mi nombre casi lo olvidé, ya me leo 
    huinca - ahora estoy más 
    cautiva que ayer
  `,
    sign: `a la negra y después
    al Blanes del Uruguay 
    que supo y no supo retratar
    desde el otro lado de todos los charcos
  `,
    images: [
      {
        url: "/img/poems/8/1.jpg",
        description: `La cautiva (1880) 
      Juan Manuel Blanes (1830-1901) 
      COLECCIÓN DE ARTE AMALIA LACROZE DE FORTABAT`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": la nueva Manca fiesta",
    poem: `las santas lo decían en rima
    me chistaban resignadas a lo divino
    dibujando yo las piedras me hablaban
    antes ya que se los viera entrar en máquinas
    de los puentes que dios puso sobre el agua grande
    me los pintoreaban con mechones que nunca
    blandearon viento norte, del cielo sacando 
    sus lenguas de cardo mas sus ojos - de vicuñita
    y alfalfa en flor,  torres de piernas los vi erguirse
    de las europas remando los aires decía el rumor
    castillos flotantes, vinieron de a uno y de a mil 
    de terrosos no tenían nada y la puna los atacó
    sandalias de plástico sus cueros finos, nos reímos
    se cargaban bolsones de papa oca como críos
    la plaza de Prado parecía endiablarse en agosto
    yo los tuve acá en la feria, llaveros les vendí
    llamitas de lana de sal cenicero mote
    cruces de alpaca y coquero de huevo de toro
    monederos eran ellos, de cosas que nunca había visto
    qué hacer con esas latas nos decíamos mas
    que ponerlas en la mesa de luz, ángeles de caucho
    el chapadur me los guardó para rato, un abrazo
    les pedí no vaya a ser que era sí verdad
    que venían del cielo.
  `,
    sign: `al Medardo Pantoja que no llegó a ver en Tilcara
    lo que las cholas de sus cuadros sí
  `,
    images: [
      {
        url: "/img/poems/9/1.jpg",
        description: `Manca fiesta (1973) 
      Medardo Pantoja (1906-1976) 
      FOTOGRAFÍA DE OBRA: DANIEL FERULLO
      `,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": entre Cruzadas, historias",
    poem: `no hay colores
    al amanecer las calles
    son sacadas de un papel
    un autobús sin descuento
    un agujero en el molar
    de diez centímetros
    no preguntes nada, nunca
    digas nada a la tarde
    de domingo
    brotes - mondadientes  
    tubérculo aguantándose
    de los bordes 
    un frasco de agua
    bloques de hierro, patas
    de sillas al arrastrarse
    suenan como teléfonos
    cuando nadie contesta
    como uñas se rompen
    los cordones
    nombres
    una foto carnet
    cartucheras y mochilas
    hasta las ocho de la noche 
    y de las voces eran ellas
    encarnadas en huesos
    mandarinas dulces
    palacios blancos
    escaleras 
     lo absurdo de lo cotidiano 
    una imaginación desgarrada
    esternón en las costillas
    posición física de facta
    el ángulo con el que apunta
    y se sienta
    es muy importante
    son pensamientos circulares
    yo en el medio de las dobles
    filas pasando el umbral
    columnas bien puestas
    los espejos - lo estudiado
    lo terrible en lo espontáneo
    los edificios son nuestros padres 
    dándonos lápices, tengo llaves
    y bastones en lugar de manos
    y ellos vienen al amanecer
  `,
    sign: `El poema “entre Cruzadas, historias” está inspirado y toma pequeños fragmentos del comienzo y
    del final de: Potestad (Eduardo Pavlovsky).
  `,
    images: [
      {
        url: "/img/poems/10/1.jpg",
        description: `Desocupados (1959)
      Ricardo Carpani (1930-1997)
      MUSEO NACIONAL DE BELLAS ARTES DE NEUQUÉN`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": el Drama",
    poem: `Se corta el lino y cae el mundo:
    Se ahogan los ves hundirse:
    
    Que tu hermano mate
    
    a tu mejor amiga
    Que el texto sea una
    
    radiografía de pueblos
    Que el eje de las fibras
    
    se corra se corten y las veas
    
    a través de los enveses son
    quimeras de una monstrua
    que amamanta y peina, cae
    con trigo la sal - el jugo
    de los granos de arroz
    al hervir el agua    
  `,
    sign: `a Forner y las guerras en serie
  `,
    images: [
      {
        url: "/img/poems/11/1.jpg",
        description: `El drama (1942) 
      Raquel Forner (1902-1988) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": Polifonía histórica-nacional",
    poem: `cuántas líneas te tiran
    diez años en flores
    los mismos bordes flacos
    donde van a pastar las vacas
    fardos de números impares
    oh, sorpresa que tenga el sol
    que pintar escaleras cuesta arriba
    y turbinas a vapor    
  `,
    sign: `a la música de Xul
  `,
    images: [
      {
        url: "/img/poems/12/1.jpg",
        description: `Barreras Melódicas (1949)
        Xul Solar (1887-1963) 
        MUSEO XUL SOLAR`,
      },
      {
        url: "/img/poems/12/2.jpg",
        description: `Cinco melodías (1948) 
        Xul Solar (1887-1963) 
        MUSEO XUL SOLAR`,
      },
      {
        url: "/img/poems/12/3.jpg",
        description: `Coral Bach “música” (1950) 
        Xul Solar (1887-1963) 
        MUSEO XUL SOLAR`,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": el Juego del pato",
    poem: `odio el juego y la persigo
    me paseo sola bajo cero
    en las estepas con cuarenta
    grados por fuera - me parece
    vulgar me llama tanto que salgo
    otra vez a buscar el choque
    qué tan grande, qué tan grueso
    se va a sentir hoy el tirón
    el deporte está en el tren
    la estación rodeada 
    de lobos
    vengo toda cargada
    con cuatro meses de embarazo
    el galope me calienta, me paseo
    de vuelta envuelta en brazos
    cacheteada rumbo al aro
    la quiero tener y cantar tanto
    dónde estará  mi vestido 
    cómo estará de arrugado
    ahí en el piso, al retrato oficial
    se le doblan las patas - tengo cáncer
    para que me tengan sacrificada
    migraña y limosna heredé carretas
    gradas para el que mira el durante
    vení que te ayudo a esperar, esperame
    cómo me gusta llevarte cinchada
    atraparte en el aire, llevarte así
    abrazada con todas mis paredes
    el clima está preparado, todos quieren 
    que me muera al llegar - sola
    hasta la puesta de escena
    que los diamantes se expongan
    los cuadros para las fiestas, él dijo:
    – este es el juego 
    nacional 
    yo vengo a pasearme
    me corto los críos, la lengua
    que hay mas patria entre los galgos
    en los números plancho, me cago y ahí
    está el resultado: a mi equipo 
    al amor y a las camelias
    nos cogen toda la tarde
    y no tocamos una sopa
    caliente en la cena    
  `,
    sign: `al diálogo que atraviesa 
    la tradición y la controversia    
  `,
    explanation: `El poema “el Juego del pato” está inspirado y toma pequeños fragmentos del comienzo y del final de: El homosexual o la dificultad de expresarse y Eva Perón (Copi).`,
    images: [
      {
        url: "/img/poems/13/1.jpg",
        description: `El juego del pato 
      Ángel Della Valle (1852-1903) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/13/2.jpg",
        description: `La vuelta del malón (1892) 
      Ángel Della Valle (1852-1903) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA
      `,
      },
    ],
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": las Parvas de noche ",
    poem: [
      {
        text: `un bloque
    de almíbar denso
    el sembrador reza
    tanto ardan las cinco
    la mala dueña
    consiste / desiste
    descalza ella hace 
    del sol una sombra
    solo ilumina de noche
    mimbre hueco dijo
    haber sido humilde
    entretejido dejó ver
    que donde nace
  `,
        align: "left",
      },
      {
        text: "          se divide",
        align: "left",
        whiteSpace: "pre",
      },
      {
        text: `entre pan y aire
    el ángel cantó
    al vientre parva
    y madrastra
    una sombra hermana
    descalza cuando el sol
    descansaba para huir
    segado estoy de pies
    y manos prestadas
    pues yo no me habito
    ni me insisto / campo
    cúpulas denme
    tiempo dijo
    haberse 
    venido
    en viento
    al verse
    arder

`,
        align: "left",
      },
    ],
    sign: `a las réplicas interminables
    a los maestros que prenden fuego    
  `,
    images: [
      {
        url: "/img/poems/14/1.jpg",
        description: `Las parvas (la pampa de hoy, 1911)
        Martín Malharro (1865-1911)`,
      },
      {
        url: "/img/poems/14/2.jpg",
        description: `Nocturno (ca. 1910)
      Martín Malharro (1865-1911)`,
      },
    ],
    explanation: `El poema “las Parvas de noche” está inspirado y toma pequeños fragmentos de: Parvas (Luis Alberto Spinetta / Almendra).`,
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
  {
    title: ": rincón Sereno",
    poem: `ya debía estar aquí
    hay dos caras y el ramo
    de los miércoles - estaban
    hechos los grises, artritis
    cien milongas por cuadra
    la cortina mueve el cuarto
    ensaladas de magnolias
    cucarachas en la tinta
    entre breñas: árboles
    de pan son las islas
    y los buques tienen 
    la vida segura
    despropósito  
    cómo es 
    de posible 
    no equivocarse
    cuán responsable
    son las palabras
    estampita de santo
    hecha de pedazos 
    de cáscara, banana
    y un peral la deja irse
    bonita frase la tuya 
    parece se estuviera ya
    burlando como si fuera 
    serpiente de los trópicos
    la soledad ha convertido
    al mantequero agreste
    en barrios enteros - baúl
    que jamás movió el pie
    un matrimonio termina
    y otro lo vio hacer ladrillos
    a la sombra de la cúpula
    antes de darse a los diarios,
    conspiración 
    fábricas de juguetes
    hay dos caras y el ramo
    ya debía estar aquí    
  `,
    sign: `al Fortunato que nunca dejó La Boca
    al Roberto que nunca dejó la calle    
  `,
    images: [
      {
        url: "/img/poems/15/1.jpg",
        description: `Desde mi estudio (ca. 1938) 
        Fortunato Lacámera (1887-1951)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/15/2.jpg",
        description: `Rincón íntimo (ca. 1930) 
        Fortunato Lacámera (1887-1951)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/15/3.jpg",
        description: `Serenidad (ca.1948) 
        Fortunato Lacámera (1887-1951)
        MUSEO BENITO QUINQUELA MARTÍN`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “rincón Sereno” está inspirado y toma pequeños fragmentos del comienzo y del final de: La isla desierta y Saverio el cruel (Roberto Arlt)`,
    height: 300,
    width: 200,
  },
  {
    title: ": metamorfiado",
    poem: `Corrugado el puerto, hombreado
    el lugar (definido) de las cosas deja
    de ser el que el ojo propone - ver
    escarapela, ventilador de pie: alud
    hojas de tabaco enrollando canción
    el país que fue el de uno deja de ser
    la propuesta definitiva, alambres en
    museos, yute debajo de los pies
    estructuras: el mineral que nos trajo
    un pasado sin cambio (de estado) más 
    allá de la presión, sometimiento dado
    a las distintas inyecciones, morfina
    un corte de luz el olivo en veranos
    mano a mano el viento trae aspas
    de plástico las caricias: musculares
    qué temprano llega y se deja - ver
    en el lugar donde el ojo quiso ir 
    y poner una caja en un museo
    mandíbulas vestidas (de frac)
    para reír un verano al país
    que fue alambre del otro
    tabaco que canta el fino
    pasado sin estado 
    de cambio allá
    donde la presa 
    es disección
    (y aceite)    
  `,
    sign: undefined,
    images: [
      {
        url: "/img/poems/16/1.jpg",
        description: `Metamorfismo (1931) 
      Juan del Prete (1897-1987) 
      MUSEO DE ARTE MODERNO DE BUENOS AIRES`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": La jaula",
    poem: `qué ve - una frutera
    esta forma es pésima
    así por fin le vuelve 
    a uno en ella algo 
    de color primario
    le llega aquel
    golpe que lo lleva 
    hasta las cuerdas
    qué ve - una estrella
    prendida con fósforo
    si le quitara esta parte
    podría ser mariposa
    pero el cuerpo no es
    para nada podría
    ser una montaña
    qué ve - un águila
    vuelta paloma blanca
    una fila de reflectores 
    y los puentes donde
    su vuelo se ve
    si se quiere
    horizontal
    la escalada divide
    dos zonas 
    de dónde vino  
    a dónde vuelve 
    se ven por zona sur: 
    barcas paseándose
    por el asfalto 
    qué ve - que no es
    simétrica y parece
    un desafío
    el flujo un hueso
    la cabeza de uno
    es sombrero del otro
    qué hago de esto
    qué hay para ver
    donde no hay más
    que murmullos
    voy a morir deforme
    y no tengo dónde
    poner las manos
    comer del tarro
    uvas verdes
    ojos de pez globo
    nosotros somos
    los oyentes ahora 
    y la boca de todas 
    las cosas en aquella luz 
    que asaltó el enjambre
    qué ve - que somos
    más blancos cada vez
    más diluidos
    que nadie
    pudo jamás romper
    su cabeza con los pies
    tocando el suelo.    
  `,
    sign: `al Forte que pintó 
    todas las muertes 
    con colores naturales    
  `,
    images: [
      {
        url: "/img/poems/17/1.jpg",
        description: `Barcas (1979) 
        Vicente Forte (1912-1980)
        COLECCIÓN PRIVADA`,
      },
      {
        url: "/img/poems/17/2.jpg",
        description: `El Pájaro Blanco (1972) 
        Vicente Forte (1912-1980)
        COLECCIÓN PRIVADA`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “La jaula” está inspirado y toma pequeños fragmentos del comienzo y del final de: Los murmullos (Luis Cano).`,
    height: 300,
    width: 200,
  },
  {
    title: ": animalistA",
    poem: [
      {
        text: `a ésta vida que llevo
no quiero darle anillo ni sábana
ni tanta libertad de expresión
intercambio amistades zoológicas
por quien del depósito - tenga llaves
quiero ser historias para que ellas
se cuenten con toros pastando
quiero estaquearme naturaleza
que me pongan en fruteras
cuando vuelvan los paisajes
quiero no estar para ser pintada
de la flor con perfume de granja
quiero no querer tanto 
lo antes dicho sobre
todo - salvajismo
que sin querer 
me conozcan
me sepan astada
que no es el azar lo que me ha traído
                                         a éste lugar	
  `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `a los cuadros de Julia y a todos 
    los (de todas) que están guardados    
  `,
    images: [
      {
        url: "/img/poems/18/1.jpg",
        description: `Toros (1897) 
        Julia Wernicke (1860-1932) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “animalistA” toma un pequeño fragmento del comienzo de: La razón de mi vida (Eva Perón).`,
    height: 300,
    width: 200,
  },
  {
    title: ": la Interrupción",
    poem: [
      {
        text: `anticipé 
absurdamente 
mi huida - tal vez
no me hayan visto
la imagen turba
los árboles están enfermos
es una isla
brusca solución 
de continuidad
intrusa
entre plantas acuáticas
la vida entera
no es atroz
descompostura
una pileta de natación
                  un museo -
          una capilla -
aligeró el sueño
fantasmas imperfectos
astillas blancas
de un hombre 
con sus discos
con sus monstruos	
inmortal
resistencia
nísperos
mármol rosa 
sus listones verdes
están armados 
con ocupaciones están
lonzanos los bajos
al molino de las mareas
qué absurdas mentes anticipan 
madrugada de las selvas
hojas ardientes y peludas
la reducción del propósito
desde pantanos
deletreo
un fonógrafo
vísceras en sus textos
barrancas, testamentos
enumeraciones dispares
a dos o tres escenas
el volumen de magia 
el derecho y la película
que firmó
hipotética sospecha
los árboles están enfermos
la etapa final de todo 
su parodia - su frontera
y exagerar la tensión
                          pliegues -
en los juegos de un tímido
sobreviven las copias
esta mano en un cuento
qué mal puede hacer
mi alma no ha pasado
aún a la imagen, haré
de una súplica el mar
mi entonación orillera
un acto piadoso 
despellejado
de afuera
para adentro
falsear con trabajo
ferrocarriles y vapores
un crucero japonés, nubes
que guardan colores agrios
una máquina de presencias
los árboles terminales
seguirán estando, son 	
la superficie de mi imagen
proyectada una vez
y otra 
en aquella isla
de piratas chinos
de barco blanco
y córneas hundidas
a cañonazos    
      `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `al barroco surrealista
    a la composición del juego
    al lenguaje al ras del suelo
    al proceso natural
    de abstracción
    
  `,
    images: [
      {
        url: "/img/poems/19/1.jpg",
        description: `Composición (1940) 
      Orlando Pierri (1913-1992) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “la Interrupción” está inspirado y toma pequeños fragmentos del comienzo y del final de: La invención de Morel (Adolfo Bioy Casares) y de los prólogos de la primera edición de 1935 y de la edición de 1954 de Historia universal de la infamia (Jorge Luis Borges).`,
    height: 300,
    width: 200,
  },
  {
    title: ": amapolas rojas",
    poem: [
      {
        text: `Se mira los pies
antes de abrirse en flor
cuerpo a cuerpo, el dibujo
su género un reflejo dorado
el opio en la medianoche
hacia el borde una mesa
mantelito firme - el eje
agarrado al recuerdo
que las guerras son 
siempre una a la luz
bailarina del fuego
a la ronda del papel
canson y sanguina
somos los muertos
días cortos atrás
                                    las trajo del campo 
                                    en un balde su alma
                                    ha pasado a este otro
                                    molde, más acabado
                                    ser nieta del “padre”
                                    que nos vio mitad
                                    mujer, por lo cobarde
                                    esfinge del nuevo mundo
                                    entierra solo agua, no
                                    deberíamos dormir
                                    mientras ellas crecen
                                    ¡que no emigren más!
                                    sombra terrible - voy
                                    a evocarte, lenta
                                    provinciana eras
                                    instinto ya abierto
                                    en sistemas, vergüenza
                                    los que aún abrigan
                                    vendrán del exterior
                                    ninguna maldición
                                    se prenderá - hoy
                                    el tirano sin rival
                                    como una garrapata
                                    se siente amanecer
                                    cuántas 
                                    veces se habrá
                                    mordido una vida
su sangre es dulce
a pesar de ser historia
nos escasea la carne 
marcas del lugar
dispuestas 
a violar el mal 
ejemplo - alondras
un tremendo cielo la ciudad
negreada de asbestos 
mosquitos
que sean los días
un río en las venas
salvajes proesas cantan
- querían solo divertirse
los carniceros son la flor
más decente, el mantel
más cuidado una copa
de anís retratada en mano`,
        align: "left",
        whiteSpace: "pre",
      },
    ],

    sign: `toda guerra está en tiempos de arte
  `,
    images: [
      {
        url: "/img/poems/20/1.jpg",
        description: `Amapolas rojas 
      Eugenia Belin Sarmiento (1860-1952) 
      MUSEO HISTÓRICO SARMIENTO`,
      },
    ],
    background: getRandom(colors),
    explanation: `  El poema “amapolas rojas” está inspirado y toma fragmentos traducidos del poema In Flanders Fields (John McCrae)
     y pequeños fragmentos del comienzo y del final de: Un episodio en la vida del pintor viajero (César Aira), 
     El Facundo (Domingo Faustino Sarmiento) y El Matadero (Esteban Echeverría).`,
    height: 300,
    width: 200,
  },
  {
    title: ": el Barrio en sus calles se Ciega",
    poem: `el barrio en sus calles se ciega
    refriega la mirada en aguarrás
    una plancha de acuarelas 
    está ahí, tendido boca arriba
    todos los tejidos saturados
    y es reflexión marcada
    a punzón - papel y dedos:
    las esculturas no eran felices
    su cuerpo fue territorio y es
    el miedo que penetra hasta
    los huesos y es la rebeldía
    escapando por todas partes
    detonaciones de escuadras
    que enloquecen el aire  
  `,
    sign: `al León que fue objeto y polémica
  `,
    images: [
      {
        url: "/img/poems/21/1.jpg",
        description: `Bairro (1980) 
        León Ferrari (1920-2013) 
        MUSEUM OF MODERN ART – NUEVA YORK`,
      },
      {
        url: "/img/poems/21/2.jpg",
        description: `El ciego (1995) 
        León Ferrari (1920-2013) 
        MUSEO DE ARTE MODERNO DE BUENOS AIRES`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “el Barrio en sus calles se Ciega” está inspirado y toma pequeños fragmentos  de: “24. El tiempo se detiene”, Operación Masacre (Rodolfo Walsh).`,
    height: 300,
    width: 200,
  },
  {
    title: ": reposo de pie con fondo azul (1889 - )",
    poem: [
      {
        text: `ilegítimas inertes indecentes
eran las piedras vestidas detrás
no solo duermen al besarse 
                            de pie
me dejan huir con ellas: las grietas
cualquier ruido - los engranes
en azul la sombra es de tantos 
cuerpos en época de telas
a espaldas revolcándose
engrapados a los nombres
en firmas sus muchos dueños
ilegítimos inertes indecentes    
  `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `al cuadro de 1891 de Sofía Posadas
    que no pude encontrar por ninguna parte    
  `,
    images: [
      {
        url: "/img/poems/22/1.jpg",
        description: `Desnudo con fondo azul (1895) 
        Eduardo Schiaffino (1858-1935) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/22/2.jpg",
        description: `Desnudo de pie 
        Eduardo Schiaffino (1858-1935) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/22/3.jpg",
        description: `Reposo (1889) 
        Eduardo Schiaffino (1858-1935)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": ilusionada",
    poem: `Sin mis manos el claro no alcanza a verse
    que la veintena sea suficiente vida
    que la curtiembre escultura planche
    embarrada de nubes azules - franca 
    en San Juan viñedo y crónica
    de uno sobre uno mismo
    
    sin mis manos el claro no alcanza
    que el carbón corra la mirada
    que el asma devuelva un pañuelo
    la siesta calle de Palermo
    terracita arrugada - ajedrez
    
    sin mis manos el claro
    que un retrato ensanche
    que una chacra traiga
    la alcance
    
    sin mis manos
    metafísica
    corriente
    
  `,
    sign: `a los ojos bien abiertos
    al lino que fue dibujo y escultura
    en tela de un país queriendo ser nuevo     
  `,
    images: [
      {
        url: "/img/poems/23/1.jpg",
        description: `Ilusión (1931) 
      Lino Enea Spilimbergo (1896-1964)
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/23/2.jpg",
        description: `La planchadora (1936) 
      Lino Enea Spilimbergo (1896-1964)
      COLECCIÓN PRIVADA`,
      },
      {
        url: "/img/poems/23/3.jpg",
        description: `Terracita (1933) 
      Lino Enea Spilimbergo (1896-1964) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": el río Chueco",
    poem: `en éste el agua corre
    dos veces, un rumor
    lleva amargo siglo
    de pobreza y desarrollo
    barracas en pecas se abre
    cargada de semillas brevas
    que llegaron a admirar
    girasoles - en la boca
    más que industria obran 
    vida y sermones
    ofrenda del hermano
    que antes de matar
    dejó ahí cultivos
    proverbios, él
    desprecio de un dios
    en tierra que se suponía
    nada iba ya a devolver
    olivo en prensa, aceite
    es el agua que roza
    con lo justo
    sus hojas apaisadas, Thalia
    y Melpomene en esta 
    higuerita de jardín
    de chapa salta la pared
    ambos sexos en sus flores
    va más allá, deja caer 
    el fruto a la corriente.    
  `,
    sign: `al Pío Collivadino que vio y dejó ver
    eso que vino a cambiar todo: lo evitable    
  `,
    images: [
      {
        url: "/img/poems/24/1.jpg",
        description: `Caín (1898-1900)
      Pío Collivadino (1869-1945)
      COLECCIÓN PRIVADA`,
      },
      {
        url: "/img/poems/24/2.jpg",
        description: `El riachuelo (1916) 
      Pío Collivadino (1869-1945) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": la dinámica de la canción",
    poem: [
      {
        text: `Reflujo es el azul
más allá de los espejos
el fuelle se ve - tan trunco
llega el aire a exprimir
lengüetas, son las mismas
de cuando tu viejo tocaba 
en la calle los naranjos
empujá desde abajo, ahí está
diafragma: el ardor se canta
lo sentís - afiebrado al sábado
en los adoquines y las masas
clasificados en fórmulas de cuatro
a la vuelta de página, se mancha
grafito en yemas, calor
y espasmos - lo sentís
el que te mima te va a vender
tachá esa palabra por otra
y dejás de sentir eso:	
            el viento empieza a llevar 
esas puntas de todos los grises
a una única dirección, el fondo
se arma desde afuera 
lo aprendiste
donde todo se dijo que era
mejor - líneas, planos y luces
trajiste el serrucho a pintar
instrumentos quebrados
dejá que te diga que sí
así somos y asusta 
pero ahí estamos
monstruos cantores 
de las épocas del lápiz papel
el color de Alicia en el país.    
      `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `al Pettoruti de proporciones escandalosas
    a los Beatles criollos
    a todas las voces perseguidas
    a las canciones 
    al pueblo    
  `,
    images: [
      {
        url: "/img/poems/25/1.jpg",
        description: `La canción del pueblo (1927) 
      Emilio Pettoruti (1892-1971) 
      MUSEO DE ARTE LATINOAMERICANO DE BUENOS AIRES`,
      },
      {
        url: "/img/poems/25/2.jpg",
        description: `La dinámica del viento (1915) 
      Emilio Pettoruti (1892-1971) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": Bagual de nacimiento",
    poem: `de una siesta en la higuera
    nació el bagual y vino
    con ideas de igualdá
    pata chueca - agónico ya
    con moretón, grafito y lana
    apretándole el cuello
    en convulsiones
    el padre: – te quiero
    querer decían los chichones
    el viento por piedra y madre
    cantora de caja en rimas
    del mar lejos ahí
    cerca las traía 
    compuestas para él 
    reza que reza el llanto
    pa dentro no más queda
    qué risa el descontento
    que trae una cuerda, hueso
    umbilical paseandosé
    riendo pa fuera
    se tuerce y de gracia
    le quiere dar otra vez
    dulzura viene del plata y ya
    sin siquiera parpadear
    se les va yendo sin querer
    – ingrato, le canta un cuarto
    hecho de chala de maíz
    al arquitecto del fierro
    ilustrado el cielo 
    se le pone a cantar aquí
    viento sabe la ventaja
    del que llora pa dentro
    a la vuelta de un carozo
    que auguriaba desentierro
    trunco poco se lamenta
    mintiendo una siesta que 
    arrancó de los choclos
    de las escobas mechas
    para armar un pincel
    el abrigo tiene ella
    locamente achicharrada
    más que grillo que no silba
    cocina besos de ruana
    pa curar las cosquillas
    piel pelusa de grano blanco
    dulce vidita corto el llanto
    muerte te hacés de rogar  
    párraco monte venga pacá
    de caridad que el rezo
    no da más, el trajín
    la raza es silencio puro
    del que no pide 
    nacer ni puede ya
    sin haberse venido
    al mundo viejo
    al pedregal
    patear más tiempo    
  `,
    sign: `al prestidigitador de cosas nuestras
    al dúo de armonías rugosas que
    junto a muchos otros vienen 
    clareando folklore - viajes
    a nuestros primeros tiempos    
  `,
    images: [
      {
        url: "/img/poems/26/1.jpg",
        description: `Nacimiento del Bagual 
        Juan Carlos Castagnino (1908-1972)
        UBICACIÓN NO ENCONTRADA`,
      },
      {
        url: "/img/poems/26/2.jpg",
        description: `Niño 
        Juan Carlos Castagnino (1908-1972)
        UBICACIÓN NO ENCONTRADA`,
      },
      {
        url: "/img/poems/26/3.jpg",
        description: `Niño (2) 
        Juan Carlos Castagnino (1908-1972)
        UBICACIÓN NO ENCONTRADA`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “Bagual de nacimiento” está inspirado y toma fragmentos de las canciones: “Niño” (Sebastián Monk), “Doña Ubenza” (Chacho Echenique), “Carnavalito del duende” (Gustavo “Cuchi” Leguizamón / Manuel Castilla), “Baguala de Juan Poquito” (María Elena Walsh). `,
    height: 300,
    width: 200,
  },
  {
    title: ": La bailarina",
    poem: [
      {
        text: `media sombra, así será
su estatua algún día
supongasé un almohadón
                  para los pies 
un triángulo de cables
con cordones atados, algas
sin volverse siquiera
alfombradas
            se prenden 
concuerdan con el fondo
de papel pintado: hoy viene
a desnudarte una vida
de veces - a veces el momento
de la sorpresa prometida
una calle atestada de curiosos
sal del mundo, hormigas
todavía gritan cada vez
más débilmente, son 
la borra al fondito
del café   
      `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `a la Lía transgresora que desató vestidos
    y a las familias de hoy antes del 30
de diciembre del 2004
  `,
    images: [
      {
        url: "/img/poems/27/1.jpg",
        description: `Retrato de Madame de L. “antes La bailarina” (1929) 
        Lía Correa Morales (1893-1975) 
        MUSEO PROVINCIAL DE BELLAS ARTES FRANKLIN RAWSON`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “La bailarina” está inspirado y toma fragmentos de la canción “Distinto” (Callejeros) y del  comienzo y del final del cuento “Todos los fuegos el fuego” (Julio Cortázar).`,
    height: 300,
    width: 200,
  },
  {
    title: ": Curupaytí, de la batalla después",
    poem: `cándida luz de media tarde
    aliada de los pastizales
    sobre tela mástil y uniforme
    se arrastran en trazos de sangre
    
    en la desnudez de los cuerpos
    en los charcos de agua
    y el frescor que será textura 
    media memoria 
    
    mis soldaditos de óleo 
    crudas miniaturas
    
    llave de granada
    esa mixtura de patria
    la del brazo cercenado
    la de esta mano menos hábil
    
    pliegues y sombras
    el azul no me basta
    para cubrir las bajas
    y a los prisioneros los puedo contar
    con los dedos que me restan    
  `,
    sign: `al Cándido López de 1893`,
    images: [
      {
        url: "/img/poems/28/1.jpg",
        description: `Después de la Batalla de Curupaytí (1893) 
        Cándido López (1840-1902) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": desembarcó Desasosiego",
    poem: `está cargado 
    se cantó y repitieron la costumbre
    de intercambiar muecas - pasa
    al petiso le pica la nariz
    el más viejo ni tantos tiene
    para pelearle una mano apenas
    llega a veintitrés, el mala pata
    los hace tambalear y el pescador
    pisa el cebo sin darse 
    a las cuentas
    atados de veinte
    por veinte apagando
    y volviendo a dar mecha
    en el televisor la nieve es blanca
    no se hunde ni hace barro
    y no atraviesa nada 
    la reunión - pasa
    por el mercado de abasto
    el humito que despiden sus bocas 
    los pichis del nuero en el tobogán
    de la plaza del barrio, pasan las valvas
    musgo que resbala en las suelas, salitre
    de las napas un caparazón de huevo
    pintan medialunas, enlatados
    en el café: herrumbre
    hambre de día de usted se trata
    no pasá, sino pasa
    como las uvas
    así debían de decir 
    no cantale, cantelé 
    almacenero tramposo
    que no hay flor ni hembra  
    que si puede te va a mentir
    y se llevan las buenas
    el viejo se guarda y abre
    hay que saber de sus caras
    de las noches de cuevas, no
    le cuenta a nadie y al final
    a sus nietos les puede besar
    la frente y los raspones
    se cantó se pasó y se fue mejor
    ya se reparte otra mano, el cabo
    apoya otra ronda, hordas
    de sueños pibes ralos
    por el cagazo hay quién 
    dice supervivientes
    las tablas se balanceaban
    pongalé un papelito doblado
    a la cuarta pata, no ve usted
    que la mesa está chueca y que
    esos mares nunca serán azules
    pero las olas van a correr
    en algún bodegón escondido
    cargaditas de espuma
    desbordada en los vasos
    como corderitos - pasan
    a favor del viento sur
    hacia el lado de los tantos
    que llevan del mazo
    ese perfume de algas
    y nieve cocida en mate
    esos pedazos de arcilla
    que nunca dejarán
    de ser cielo.    
  `,
    sign: `al pintor de guerras que las sigue pintando
    a los pichis de otras Pichiceras
    y al Fogwill punk    
  `,
    images: [
      {
        url: "/img/poems/29/1.jpg",
        description: `Desembarco del Ejército Argentino frente a las trincheras de Curuzú (1891) 
      Cándido López (1840-1902) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: `El poema “desembarcó Desasosiego” está inspirado y toma fragmentos  del comienzo y el final de  “Los Pichiciegos” (Rodolfo Fogwill).`,
    height: 300,
    width: 200,
  },
  {
    title: ": Muchacho de urpila",
    poem: [
      {
        text: `               hambreado
arrullo que anuncia
la muerte de un niño
es el que enternece
la mano atada
albahaca entera 
vuelta al pecho
doble en la segunda
el zorrito zapateo
hace del pensador
de Piedra Blanca
en la puerta del infierno
un hito viejo y nuestro    
  `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `a la tradición perdida, oculta, robada
    o enterrada que nos sigue llevando
    a embellecernos con la opresión
    a cruzar las aguas para inspirarnos 
    en otras y volver a buscarla    
  `,
    images: [
      {
        url: "/img/poems/30/1.jpg",
        description: `La urpila (1946) 
        Ramón Gómez Cornet (1898-1964)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/30/2.jpg",
        description: `Muchacho 
        Ramón Gómez Cornet (1898-1964)
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA
        `,
      },
      {
        url: "/img/poems/30/3.jpg",
        description: `Santiagueños (1927) 
        Ramón Gómez Cornet (1898-1964) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/30/4.jpg",
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": abuelada",
    poem: [
      {
        text: `                             Te acordás
cuando los pies no tocaban
el suelo, piano bien piano
sentí el peso de las teclas
esta parte no se tiene
que escuchar tanto
así te cantaba, la página
abierta - por más que se diga
que duró algo así, como diez años
acercate más al instrumento, dejá
que la martillera haga sus golpes
y el paño de la sordina te ayude
a respetar el carácter  -    
      `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `a los “restauradores”
    y sus partituras escritas 
    en los ’30    
  `,
    images: [
      {
        url: "/img/poems/31/1.jpg",
        description: `La abuelita (1939) 
      Ana Weiss de Rossi (1892-1953) 
      MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": auto Retrato",
    poem: `saltando la cuerda
    así me veo a mí
    mismo amuchado 
    tan serio(a) y en serie
    con diosito en el reloj
    de atrás pero antes
    del umbral, estoy verde
    y los rieles todavía
    se corretean    
  `,
    sign: `a las formaciones del Mitre 
    y del Sarmiento
    del  2012
    del ’78
    y del ’70    
  `,
    images: [
      {
        url: "/img/poems/32/1.jpg",
        description: `Autoretrato (1929) 
      Dora Cifone (1898 – 1964) 
      MUSEO PROVINCIAL DE BELLAS ARTES EMILIO PETTORUTI`,
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: ": entre Duraznos las voces",
    poem: [
      {
        text: `que el carozo no fuera semilla 
que el que sangra no soy yo

que la cabra no quiso ser mártir

que no fui pintor ni mujer
que el campo no impresiona

que la caridad no protege
que no fui otoño ni flor-fruto

que el venirse en otro no es amor
          ni admiración ni homenaje

qué haría hoy si fuera a saber
que estas son las últimas hojas

y todas las obras tienen dos títulos    
      `,
        align: "left",
        whiteSpace: "pre",
      },
    ],
    sign: `al Fernando que vio
    la naturaleza ya pintada
   y la pintura libre en el campo   
  `,
    images: [
      {
        url: "/img/poems/33/1.jpg",
        description: `Entre duraznos floridos (1915) 
        Fernando Fader (1882- 1935) 
        COLECCION DE ARTE AMALIA LACROZE DE FORTABAT`,
      },
      {
        url: "/img/poems/33/2.jpg",
        description: `Últimas hojas o Cuidando las cabras (ca. 1926) 
        Fernando Fader (1882- 1935) 
        MUSEO NACIONAL DE BELLAS ARTES DE ARGENTINA`,
      },
      {
        url: "/img/poems/33/3.jpg",
      },
    ],
    background: getRandom(colors),
    explanation: undefined,
    height: 300,
    width: 200,
  },
  {
    title: "",
    poem: [
      {
        text: `
        
        It was the best of times, it was the worst of times, it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way—in short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.


        (Charles Dickens – Historia de Dos Ciudades, Capítulo I, “La época”)
        
        
        Era el mejor de los tiempos, era el peor de los tiempos, la edad de la sabiduría, y también de la locura; la época de las creencias y de la incredulidad; la era de la Luz y de las Tinieblas; la primavera de la esperanza y el invierno de la desesperación. Todo lo poseíamos, pero no teníamos nada; caminábamos en derechura al cielo y nos extraviábamos por el camino opuesto—en una palabra, aquella época era tan parecida a la actual, que nuestras más notables autoridades insisten en que, tanto en lo que se refiere al bien como al mal, solo es aceptable la comparación en grado superlativo.            
      `,
        align: "center",
      },
      {
        text: `        
        -----------------------------------------------

        que este libro sea de esos
        que te obligan a abrir otros:
      `,
        align: "center",
      },
    ],
    images: ["/img/poems/34/1.png"],
    disableImages: true,
    sign: undefined,
    background: getRandom(colors),
    height: 300,
    width: 200,
  },
];

export default poesias;
