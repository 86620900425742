import React, { useState } from "react";
import { JustifiedGrid } from "@egjs/react-grid";
import Card from "./Card";
import poesias from "./poesias";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import PoemContent from "./PoemContent";
import Header from "./Header";
import Help from "./Help";

const View = () => {
  const [selectedElement, setSelectedElement] = useState(null);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedElement(null);
  };

  const [openHelp, setOpenHelp] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60vw",
    height: "85vh",
    bgcolor: "#f8edeb",
    borderRadius: "15px",
    boxShadow: 24,
    color: "#393D3F",
    p: 4,
  };

  return (
    <div id="outer-container">
      <main id="page-wrap">
        <Help open={openHelp} handleClose={() => setOpenHelp(false)} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <PoemContent poem={selectedElement} onClose={handleClose} />
            </Box>
          </Fade>
        </Modal>
        <Header isMobile={false} />

        <div style={{ height: "100%", width: "98vw", margin: "1vw" }}>
          <JustifiedGrid
            gap={15}
            defaultDirection={"end"}
            align="align"
            columnRange={[3, 4]}
            rowRange={0}
            isCroppedSize={false}
            displayedRow={-1}
            aspectRatio={1}
          >
            {poesias.map((e, i) => {
              return (
                <Card
                  onClick={() => {
                    setSelectedElement(poesias[i]);
                    handleOpen();
                  }}
                  background={e.background || undefined}
                  width={e.width * 0.1 || undefined}
                  height={e.height * 0.1 || undefined}
                  imgs={e.images}
                  key={`element${i}`}
                  id={i}
                  poem={e.poem}
                  title={e.title}
                />
              );
            })}
          </JustifiedGrid>
        </div>
      </main>
    </div>
  );
};

export default View;
