import React from "react";
import { isMobile } from "react-device-detect";
import Header from "./Header";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";

const InfoView = () => {
  return (
    <div id="outer-container">
      {isMobile && (
        <Menu right width={150}>
          <div
            id="h-menu"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              color: "#80727B",
              height: "93vh",
              padding: "2.5em 1.5em 0",
            }}
          >
            <Link to="/">Home</Link>
            <Link to="/info">+Info</Link>
          </div>
        </Menu>
      )}
      <main id="page-wrap">
        <Header isMobile={isMobile} />
        <div style={{ height: "100%", width: "98vw" }}>
          {isMobile ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
                color: "#80727B",
                height: "85vh",
                padding: "2.5em 1.5em 0",
                paddingBottom: 0,
                textAlign: "justify",
              }}
            >
              <img
                src="/img/author.jpg"
                alt="Rodrigo Orquera"
                style={{
                  maxWidth: "50vw",
                  maxHeigth: "50vh",
                  width: "auto",
                  height: "auto",
                  //overflow: "hidden",
                  borderRadius: "15px",
                }}
              />
              <p style={{ wordWrap: "break-word", fontSize: "small" }}>
                Contacto: <br />
                Rodrigo Orquera Vecile
                <br />
                Jujuy, Argentina, 1991
                <br />
                <a href="mailto:rodrigoorquera@gmail.com">
                  rodrigoorquera@gmail.com
                </a>
              </p>

              <p
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "small",
                  textAlign: "justify",
                }}
              >
                {`Licenciado en Artes Musicales (egresado de la UNA, donde también trabajó como docente), compositor, guitarrista, cantautor y escritor. Estudia Artes de la Escritura en la UNA. Se formó con escritores, poetas y dramaturgos como Iosi Havilio, Sebastián Marínez Daniell, Claudia Masin, Ignacio Gómez Bustamante y Ariel Farace. Sus poemas y obras de teatro fueron seleccionados para participar del Festival Artístico de la UNA en Argentina, en Diciembre del 2017. Su novela “Trun Co.” fue la ganadora del IX Concurso “Granajoven 2018” (Granada, España) y publicada por la Editorial Alhulia. En 2020, sus poemas fueron incluidos en la antología “Desde el estanque” (Salta el pez). En Diciembre de 2020 publicó su primer libro de poemas, “Norte” (Salta el pez). En 2021, su monólogo “Las calles están repletas” fue llevado a escena por la productora Praktores, en la cual también participó como asistente de dirección.`}
              </p>
              <p
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "small",
                  alignSelf: "flex-start",
                  marginBottom: "5px",
                  marginTop: "2em",
                }}
              >
                {`Procedimiento (La mirada sobre la mirada)`}
              </p>
              <p
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "small",
                  fontWeight: "bold",
                  alignSelf: "flex-start",
                  marginTop: "0px",
                  marginBottom: "0px",
                }}
              >
                “Fue lo mejor / de las épocas / lo peor”…
              </p>
              <p
                style={{
                  whiteSpace: "pre-line",
                  fontSize: "small",
                  textAlign: "justify",
                }}
              >
                {`era el título original del presente poemario dedicado a reconstruir, desde diferentes perspectivas y diversas disciplinas, los momentos más importantes de la historia de nuestro país. El conjunto de textos trata de rever lo terrible que nos construye. Cada uno de los poemas de éste libro está inspirado en uno o más cuadros de artistas argentines, en actividad durante los siglos XIX y XX. Asimismo, también se toman pequeños fragmentos de textos literarios y de canciones, de autores nacionales.

“Fue lo mejor / de las épocas / lo peor” es un intento de crear -o bien, poner en funcionamiento- una mirada colectiva que avale todas las aristas y todas las contradicciones que se desprenden de un centro: el evento histórico. Cada poema es, en sí, ya un espejo que refleja de manera distorsionada las miradas de otres artistas, siempre en retrospectiva. A su vez, les espectadores/lectores podrán generar sus propias miradas e incluso expandir el proceso creativo, de la misma manera en la que un músico ejecuta, con el uso de una partitura, una pieza musical. 

FUEPOESÍA.AR es un proyecto en movimiento, que busca desarrollar el germen que se muestra hoy en esta plataforma. La idea -quizás ambiciosa- a futuro, es llegar a generar un vínculo entre los museos de todo el país: creando un recorrido literario-pictórico gratuito, de manera virtual y/o presencial; con el fin de contribuir al interés en las obras de nuestro acervo cultural. El objetivo principal es el acceso libre al material literario, musical, visual e histórico.

El presente proyecto no tiene fines de lucro, y cuenta con el aval del Programa de Apoyo a la Realización Artística y Cultural, y de la Secretearía de Arte y Cultura de la Universidad Nacional de La Plata.`}
              </p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <img
                  src="/img/SAyC2.png"
                  alt="Secretaria de cultura universidad de la plata"
                  style={{ width: "35vw" }}
                />
                <img
                  src="/img/PAR.png"
                  alt="PAR Programa de apoyo y realizacion artistica y cultural"
                  style={{ width: "35vw" }}
                />
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
                color: "#80727B",
                height: "80vh",
                padding: "2.5em 1.5em 0",
                paddingBottom: 0,
              }}
            >
              <div style={{ width: "40vw" }}>
                <img
                  src="/img/author.jpg"
                  alt="Rodrigo Orquera"
                  style={{
                    width: "35vw",
                    maxHeigth: "50vh",
                    /* width: "auto", */
                    height: "auto",
                    overflow: "hidden",
                    borderRadius: "15px",
                  }}
                />
                <p
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "medium",
                    textAlign: "justify",
                  }}
                >
                  {`Licenciado en Artes Musicales (egresado de la UNA, donde también trabajó como docente), compositor, guitarrista, cantautor y escritor. Estudia Artes de la Escritura en la UNA. Se formó con escritores, poetas y dramaturgos como Iosi Havilio, Sebastián Marínez Daniell, Claudia Masin, Ignacio Gómez Bustamante y Ariel Farace. Sus poemas y obras de teatro fueron seleccionados para participar del Festival Artístico de la UNA en Argentina, en Diciembre del 2017. Su novela “Trun Co.” fue la ganadora del IX Concurso “Granajoven 2018” (Granada, España) y publicada por la Editorial Alhulia. En 2020, sus poemas fueron incluidos en la antología “Desde el estanque” (Salta el pez). En Diciembre de 2020 publicó su primer libro de poemas, “Norte” (Salta el pez). En 2021, su monólogo “Las calles están repletas” fue llevado a escena por la productora Praktores, en la cual también participó como asistente de dirección.`}
                </p>
                <p style={{ wordWrap: "break-word", fontSize: "medium" }}>
                  Contacto: <br />
                  Rodrigo Orquera Vecile
                  <br />
                  Jujuy, Argentina, 1991
                  <br />
                  <a href="mailto:rodrigoorquera@gmail.com">
                    rodrigoorquera@gmail.com
                  </a>
                </p>
              </div>
              <div
                style={{
                  width: "60vw",
                  marginLeft: "10px",
                  textAlign: "justify",
                }}
              >
                <p
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "medium",
                  }}
                >
                  {`Procedimiento (La mirada sobre la mirada)`}
                </p>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "medium",
                    fontWeight: "bold",
                  }}
                >
                  “Fue lo mejor / de las épocas / lo peor”…
                </p>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    fontSize: "medium",
                    textAlign: "justify",
                  }}
                >
                  {`era el título original del presente poemario dedicado a reconstruir, desde diferentes perspectivas y diversas disciplinas, los momentos más importantes de la historia de nuestro país. El conjunto de textos trata de rever lo terrible que nos construye. Cada uno de los poemas de éste libro está inspirado en uno o más cuadros de artistas argentines, en actividad durante los siglos XIX y XX. Asimismo, también se toman pequeños fragmentos de textos literarios y de canciones, de autores nacionales.

“Fue lo mejor / de las épocas / lo peor” es un intento de crear -o bien, poner en funcionamiento- una mirada colectiva que avale todas las aristas y todas las contradicciones que se desprenden de un centro: el evento histórico. Cada poema es, en sí, ya un espejo que refleja de manera distorsionada las miradas de otres artistas, siempre en retrospectiva. A su vez, les espectadores/lectores podrán generar sus propias miradas e incluso expandir el proceso creativo, de la misma manera en la que un músico ejecuta, con el uso de una partitura, una pieza musical. 

FUEPOESÍA.AR es un proyecto en movimiento, que busca desarrollar el germen que se muestra hoy en esta plataforma. La idea -quizás ambiciosa- a futuro, es llegar a generar un vínculo entre los museos de todo el país: creando un recorrido literario-pictórico gratuito, de manera virtual y/o presencial; con el fin de contribuir al interés en las obras de nuestro acervo cultural. El objetivo principal es el acceso libre al material literario, musical, visual e histórico. 

El presente proyecto no tiene fines de lucro, y cuenta con el aval del Programa de Apoyo a la Realización Artística y Cultural, y de la Secretearía de Arte y Cultura de la Universidad Nacional de La Plata.`}
                </p>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    padding: "20px",
                  }}
                >
                  <img
                    src="/img/SAyC2.png"
                    alt="Secretaria de cultura universidad de la plata"
                    style={{ width: "12vw" }}
                  />
                  <img
                    src="/img/PAR.png"
                    alt="PAR Programa de apoyo y realizacion artistica y cultural"
                    style={{ width: "12vw" }}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default InfoView;
