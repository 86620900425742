import React, { useState } from "react";
import Card from "./Card";
import poesias from "./poesias";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import PoemContent from "./PoemContent";
import Help from "./Help";
import Header from "./Header";
import { slide as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";

const MobileView = () => {
  const [selectedElement, setSelectedElement] = useState(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedElement(null);
  };

  const [openHelp, setOpenHelp] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "95vw",
    height: "95vh",
    bgcolor: "#f8edeb",
    borderRadius: "15px",
    boxShadow: 24,
    color: "#393D3F",
    p: 4,
  };

  return (
    <div id="outer-container">
      <Menu right width={150}>
        <div
          id="h-menu"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "center",
            color: "#80727B",
            height: "93vh",
            padding: "2.5em 1.5em 0",
          }}
        >
          <Link to="/">Home</Link>
          <Link to="/info">+Info</Link>
        </div>
      </Menu>
      <main id="page-wrap">
        <Header isMobile={true} />
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <PoemContent poem={selectedElement} onClose={handleClose} />
            </Box>
          </Fade>
        </Modal>
        <div
          style={{
            height: "100%",
            width: "85vw",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Help open={openHelp} handleClose={() => setOpenHelp(false)} />
          {poesias.map((e, i) => {
            return (
              <Card
                onClick={() => {
                  setSelectedElement(poesias[i]);
                  handleOpen();
                }}
                //showText={showText}
                background={e.background || undefined}
                width={e.width * 0.1 || undefined}
                height={e.height * 0.1 || undefined}
                imgs={e.images}
                key={`element${i}`}
                id={`element${i}`}
                poem={e.poem}
                title={e.title}
              />
            );
          })}
        </div>
      </main>
    </div>
  );
};

export default MobileView;
