import Divider from "@mui/material/Divider";
import { Link } from "react-router-dom";

export default function Header({ isMobile }) {
  return isMobile ? (
    <div className="header">
      <div
        style={{
          justifyContent: "start",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          whiteSpace: "nowrap",
        }}
      >
        <img style={{ height: "7vh", padding: 10 }} src="/fp.png" alt="" />
        <Divider
          orientation="vertical"
          sx={{ marginTop: "25px", marginBottom: "25px", alignSelf: undefined }}
          flexItem
        />
        <Link className="header-title" to="/">
          Fue Poesía
        </Link>
      </div>
    </div>
  ) : (
    <div className="header">
      <div
        style={{
          justifyContent: "start",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <img style={{ height: "7vh", padding: 10 }} src="/fp.png" alt="" />
        <Divider
          orientation="vertical"
          sx={{ marginTop: "25px", marginBottom: "25px", alignSelf: undefined }}
          flexItem
        />
        <Link className="header-title" to="/">
          Fue Poesía
        </Link>
      </div>
      <div
        id="h-menu"
        style={{
          justifyContent: "start",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Link style={{ marginRight: 20 }} to="/">
          Home
        </Link>
        <Link style={{ marginRight: 40 }} to="/info">
          +Info
        </Link>
      </div>
    </div>
  );
}
