import React from "react";
import { isMobile } from "react-device-detect";

const Card = ({ imgs, title, id, onClick }) => {
  return (
    <div
      class={`image-full ${isMobile && "image-mobile"}`}
      id={id}
      style={{ borderRadius: 3 }}
    >
      <div class="image-button">
        <button onClick={onClick}>Abrir el poema</button>
      </div>
      <img src={imgs[0]?.url || imgs[0]} alt="" />
      <p class="image-description">{title}</p>
    </div>
  );
};

export default Card;
